import urlSlugService from "../services/UrlSlugService";
import redirectionService from "../services/RedirectionService";
import Layout from "../components/layouts/main";
import React from "react";
import Article from "../components/article/article";
import Category from "../components/category/category";
import { IBaseBox, ICategory, IURLSLUG } from "../model/base";
import ArticleService from "../services/ArticleService";
import adsService from "../services/AdsService";

import { useMediaQuery } from "react-responsive";
import { mergeByProperty, getPathAndQuery, classDivisionCateMap, findIdAds, addAdsToContent, findAdsByIds } from "../util/functionGlobal";
import utils from "../util/utils";
import { chunkArray } from "../util/chunkArray";
import moment from "moment";
import ArticleRatingService from "../services/ArticleRatingService";

const Base = ({
  urlSlug,
  articleNew,
  article,
  count,
  listArticle,
  categories,
  resultCoupon,
  articleRevelant,
  articleInterested,
  articleTop5,
  cate,
  listAds,
  category,
  page,
  totalRate,
}: {
  urlSlug: IURLSLUG;
  listArticle: IBaseBox[][];
  article: IBaseBox;
  count: number;
  categories: ICategory[];
  resultCoupon;
  articleRevelant;
  articleInterested;
  articleNew;
  articleTop5: IBaseBox[];
  cate;
  listAds;
  category;
  page: number;
  totalRate: number;
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  if (isMobile) {
    articleRevelant = articleRevelant?.concat(articleNew);
  }
  return (
    <Layout
      page={page}
      totalPage={Math.ceil(count / 15)}
      showBanner={urlSlug?.object_type === "CATEGORY"}
      seoDescription={
        urlSlug?.object_type == "ARTICLE" ? article?.seo_description : category?.seo_description ? category.seo_description : category?.description
      }
      seoTitle={
        urlSlug?.object_type == "ARTICLE"
          ? article?.seo_title
            ? article.seo_title
            : article.title
          : category?.seo_title
          ? category.seo_title
          : category?.title
      }
      canonical={article?.canonical ? article.canonical : urlSlug.alias}
      seoImage={urlSlug?.object_type === "ARTICLE" ? process.env.NEXT_PUBLIC_BASE_IMAGE_URL + `/articles/${article.image_src}` : ""}
      point={urlSlug?.object_type == "ARTICLE" ? article?.article_rating_point?.point : null}
      totalRate={totalRate}
      isArticel={urlSlug?.object_type === "ARTICLE"}
      article={urlSlug?.object_type == "ARTICLE" ? article : null}
    >
      {urlSlug?.object_type == "ARTICLE" ? (
        <Article
          article={article}
          articleNew={articleNew}
          resultCoupon={resultCoupon}
          articleRevelant={articleRevelant}
          categories={categories}
          articleInterested={articleInterested}
          listAds={listAds}
        />
      ) : (
        <Category
          idCate={category.id}
          categories={categories}
          data={listArticle}
          count={count}
          articleSlide={articleTop5}
          listAds={listAds}
          cateName={category.title}
        />
      )}
    </Layout>
  );
};

export default Base;

export const getServerSideProps = async (context) => {
  const { res } = context;
  const alias = context.query.alias;
  const pathAndQuery = getPathAndQuery(context.resolvedUrl);
  const { limit, page, orderBy, query, sortBy } = context.query;
  let article: IBaseBox;
  let articleTop5: IBaseBox[];
  let cate;
  let category;
  let articleRevelant, articleNew, articleInterested: IBaseBox[];
  let listArticle;
  let count;
  var totalRate = 0;
  let listPath = [process.env.NEXT_PUBLIC_BASE_PATH + pathAndQuery.path, process.env.NEXT_PUBLIC_BASE_PATH + pathAndQuery.path + "/"];
  let [urlSlug, categories, resultCoupon, redirection, listAds] = await Promise.all([
    urlSlugService.getByAlias(alias),
    utils.getSharedData(),
    ArticleService.filter({
      tag_alias: "sapo-khuyen-mai",
      limit: 2,
      page: 0,
      status: true,
    }),
    redirectionService.getRedirect(listPath),
    adsService.getAdsByStatus(1),
  ]);

  if (redirection) {
    if (redirection.target.startsWith("/blog/")) {
      res.writeHead(301, { location: redirection.target + pathAndQuery.query });
      res.end();
    } else {
      return {
        redirect: {
          permanent: false,
          destination: redirection.target.startsWith("http") ? redirection.target : `https://www.sapo.vn${redirection.target}`,
        },
      };
    }
  }

  if (!urlSlug) {
    return {
      notFound: true,
    };
  }
  if (urlSlug?.object_type == "ARTICLE") {
    article = await ArticleService.getOne(alias);
    if (!article || article.status !== 1) {
      return {
        notFound: true,
      };
    }
    article = {
      ...article,
      article_tags_mapping: article.article_tags_mapping?.map((item) => {
        return item.tags;
      }),
    };
    articleNew = await ArticleService.filter({
      limit: 3,
      page: 0,
      status: true,
      tags: article.article_tags_mapping?.map((item) => item.tag),
      not_in: [article.id],
    });
    let articleNewIds = articleNew.map((obj) => obj.id);

    const checkCateHoiDap = article.category_article_mapping.find((art) => art.category_id === 86);

    let checkArticleRevelant1: IBaseBox[] = await ArticleService.filter({
      limit: 4,
      page: 0,
      status: true,
      after_published_at: article.published_at || article.created_at,
      tags: article.article_tags_mapping?.map((item) => item.tag),
      not_in: checkCateHoiDap ? null : articleNewIds.concat([urlSlug.object_id]),
    });

    let checkArticleRevelant2: IBaseBox[] = await ArticleService.filter({
      limit: 5,
      page: 0,
      status: true,
      before_published_at: article.published_at || article.created_at,
      tags: article.article_tags_mapping?.map((item) => item.tag),
      not_in: checkCateHoiDap ? null : articleNewIds.concat([urlSlug.object_id]),
    });

    let articleRevelant1: IBaseBox[] = await ArticleService.filter({
      limit: 9 - Number(checkArticleRevelant2?.length || 0),
      page: 0,
      status: true,
      after_published_at: article.published_at || article.created_at,
      tags: article.article_tags_mapping?.map((item) => item.tag),
      not_in: checkCateHoiDap ? null : articleNewIds.concat([urlSlug.object_id]),
    });
    let articleRevelant2: IBaseBox[] = await ArticleService.filter({
      limit: 9 - Number(checkArticleRevelant1?.length || 0),
      page: 0,
      status: true,
      before_published_at: article.published_at || article.created_at,
      tags: article.article_tags_mapping?.map((item) => item.tag),
      not_in: checkCateHoiDap ? null : articleNewIds.concat([urlSlug.object_id]),
    });

    articleRevelant = articleRevelant1.concat(articleRevelant2);
    articleRevelant = articleRevelant.sort((a, b) => (a.articleViewCount?.count < b.articleViewCount?.count ? 1 : -1));

    let articleRevelantids = articleRevelant.map((obj) => obj.id);
    let categoriesIds = article.category_article_mapping
      ?.map((obj) => {
        return classDivisionCateMap(categories.categories, obj.category_id);
      })
      .flat();

    let articleInterested1 = [],
      articleInterested2 = [];

    let checkArticle1 = await ArticleService.filter({
      limit: 4,
      page: 0,
      status: true,
      after_created_at: article.published_at ? article.published_at : article.created_at,
      category_ids: categoriesIds,
      not_in: articleRevelantids.concat([urlSlug.object_id]),
    });

    let checkArticle2 = await ArticleService.filter({
      limit: 5,
      page: 0,
      status: true,
      before_created_at: article.published_at ? article.published_at : article.created_at,
      category_ids: categoriesIds,
      not_in: articleRevelantids.concat([urlSlug.object_id]),
    });

    articleInterested1 = await ArticleService.filter({
      limit: 9 - Number(checkArticle2?.length || 0),
      page: 0,
      status: true,
      after_published_at: article.published_at ? article.published_at : article.created_at,
      category_ids: categoriesIds,
      not_in: articleRevelantids.concat([urlSlug.object_id]),
    });

    articleInterested2 = await ArticleService.filter({
      limit: 9 - Number(checkArticle1?.length || 0),
      page: 0,
      status: true,
      before_published_at: article.published_at ? article.published_at : article.created_at,
      category_ids: categoriesIds,
      not_in: articleRevelantids.concat([urlSlug.object_id]),
    });

    articleInterested = articleInterested1?.concat(articleInterested2);

    //Xy ly tam thoi Ads
    if (listAds && listAds.length > 0) {
      let listIdAds = findIdAds(article.content1);
      if (listIdAds.length > 0) {
        let listAdsArticle = findAdsByIds(listAds, listIdAds);
        if (listAdsArticle && listAdsArticle.length > 0) {
          let contentFinal = addAdsToContent(listAdsArticle, article.content1);
          article = { ...article, content1: contentFinal };
        }
      }
    }
    article = { ...article, categories_ids: categoriesIds };

    [totalRate] = await Promise.all([ArticleRatingService.count({ article_id: Number(article.id) })]);
  }
  if (urlSlug?.object_type == "CATEGORY") {
    cate = classDivisionCateMap(categories.categories, urlSlug.object_id);
    count = await ArticleService.countArticle({
      category_ids: cate,
      title: query,
      status: true,
    });
    const totalPage = Math.floor((count - 1) / (limit ? limit : 15) + 1);
    [listArticle] = await Promise.all([
      ArticleService.filter({
        category_ids: cate,
        title: query,
        limit: 15,
        page: page ? (page <= totalPage ? Number(page) - 1 : totalPage) : 0,
        sort_by: "desc",
        order_by: "published_at",
        status: true,
      }),
    ]);

    const listArticleModCategory = listArticle?.map((item) => {
      return {
        ...item,
        categories: mergeByProperty(categories.categories, item.category_article_mapping, "id", "category_id"),
      };
    });
    listArticle = chunkArray(listArticleModCategory, 15);
    category = categories.categories.find((cate) => cate.id === urlSlug.object_id);

    const getStartDate = (i: number) => {
      return moment().subtract(i, "months").startOf("months").toDate();
    };

    const getTop5 = async (i?: number, limit?: number, notIds?: number[]) => {
      if (!i) {
        i = 0;
      }
      if (!limit) {
        limit = 0;
      }
      let [topArticles] = await Promise.all([
        ArticleService.getAll({
          status: true,
          after_published_at: getStartDate(3),
          before_published_at: moment().toDate(),
          category_ids: cate,
          not_in: notIds,
        }),
      ]);
      topArticles.forEach((item) => {
        if (!item.article_view_counts) {
          item.article_view_counts = { article_id: item.id, count: 0 };
        }
      });
      topArticles = topArticles.sort((a, b) => (a.article_view_counts?.count < b.article_view_counts?.count ? 1 : -1)).slice(0, 5 - limit);
      return topArticles;
    };
    let [sortByview] = await Promise.all([getTop5()]);

    let dataIds = sortByview.map((obj) => obj.id);
    if (sortByview.length < 5) {
      let [add] = await Promise.all([getTop5(1, sortByview.length, dataIds)]);
      articleTop5 = sortByview.concat(add);
    } else {
      articleTop5 = sortByview;
    }
  }

  return {
    props: {
      ...categories,
      urlSlug,
      articleNew,
      article,
      listArticle,
      count,
      resultCoupon,
      articleRevelant,
      articleInterested,
      articleTop5,
      listAds,
      cate,
      category,
      page,
      totalRate,
    },
  };
};
